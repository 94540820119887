@import url('https://fonts.googleapis.com/css?family=Black+And+White+Picture&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url(./assets/fonts/BigCaslonRegular.otf) format('woff');
}

body {
  margin: 0;
  font-family: 'Poppins', 'serif', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul:not(.list-none) li:before {
  content: '●';
  padding-right: 6px;
}

ul{
  text-align: left;
  width: auto;
}

input[type='range']::-moz-range-thumb {
  width: 20px;
  height: 20px;
  appearance: none;
  cursor: ew-resize;
  background: #fff;
  border: none;
  box-shadow: -405px 0 0 400px #605e5c;
  border-radius: 50%;
}

input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  appearance: none;
  cursor: ew-resize;
  background: #fff;
  border: none;
  box-shadow: -405px 0 0 400px #605e5c;
  border-radius: 50%;
}

.centeredAb {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.validation .validation-text {
  text-align: center;
  /* padding: 2px 6px; */
  z-index: 100;
}

@media screen {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input:focus {
    outline: none !important;
  }

  button:focus {
    outline: none !important;
  }

  input:checked + i.material-icons {
    display: block;
  }
}

button {
  outline: none;
}

input {
  outline: none;
}

.dark-accent-shadow{
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
}

.divCenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu{
  transition: height .6s;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  background-image: url('./assets/bg-nav.svg');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.menu.open{
  height: 100vh;
}

#menu-icon {
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#menu-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #000000;
  border-radius: 99999999px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#menu-icon span:nth-child(1) {
  top: 0px;
}

#menu-icon span:nth-child(2),#menu-icon span:nth-child(3) {
  top: 10px;
}

#menu-icon span:nth-child(4) {
  top: 20px;
  height: 2px;
}

#menu-icon.open span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
  background: #008C8E;
}

#menu-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #008C8E;
}

#menu-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background: #008C8E;
}

#menu-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
  background: #008C8E;
}

.neoButton{
  padding: 10px 30px;
  border-radius: 6px;
  background: #ebebeb;
  box-shadow:  11px 11px 22px #a9a9a9,
  -11px -11px 22px #ffffff;
}

.neoEle{
  background: #ebebeb;
  box-shadow:  11px 11px 22px #a9a9a9,
  -11px -11px 22px #ffffff; 
}

.neoButton:hover {
  box-shadow:  6px 6px 12px #a9a9a9,
             -6px -6px 12px #ffffff; 
}

.neoReverseEle{
  background: #ebebeb;
  box-shadow: inset 11px 11px 22px #c8c8c8,
            inset -11px -11px 22px #ffffff;
}

.neoEle:hover {
  box-shadow:  6px 6px 12px #a9a9a9,
             -6px -6px 12px #ffffff; 
}

.neoButtonDark{
  padding: 10px 30px;
  border-radius: 6px;
  background: #2e2e2e;
  box-shadow:  11px 11px 22px #242424,
  -11px -11px 22px #383838;
}

.neoButtonDark:hover {
  box-shadow:  6px 6px 12px #242424,
             -6px -6px 12px #383838; 
}

.neoButtonRed{
  padding: 10px 30px;
  border-radius: 6px;
  background: #008C8E;;
  box-shadow:  10px 10px 30px #007779,
  -10px -10px 30px #00a1a3;
}

.neoButtonRed:hover {
  box-shadow:  5px 5px 15px #007779,
  -5px -5px 15px #00a1a3;
}

q::before {
  content: open-quote;
  font-size: 2rem;
  color: #008C8E;
  font-family: 'Black And White Picture', sans-serif;
}
q::after {
  content: close-quote;
  font-size: 2rem;
  color: #008C8E;
  font-family: 'Black And White Picture', sans-serif;
}

.bgColor1{
  background-color: #ebebeb;
  color: black;
}

.bgColor2{
  background-color: #2e2e2e;
  color: white;
}

.bgColor3{
  background-color: #008C8E;
  color: white;
}

th{
  padding: 0.1rem;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 700;
  white-space: nowrap;
  background-color: #bfbfbf;
  border-radius: 5px;
}

td{
padding: 0.1rem;
font-size: 15px;
font-weight: 400;
}

#tableEle{
box-shadow:  4px 4px 3px #a9a9a9,
-4px -4px 3px #ffffff;
padding: 0.5rem;
border-radius: 7px;
margin: 3px 2px;
}

#tableEle.active{
  box-shadow:  4px 4px 3px #8cdbb0,
  -4px -4px 3px #a1fcca;
  }

td>#tableEle{
display: flex;
align-items: center;
}

.customFont {
  font-family: 'MyFont','Poppins', 'serif', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;;
}

.customColor_primary {
  color: #008C8E
}

.customColor_bg_primary {
  background-color: #008C8E
}

.link {
  margin: 1rem 0rem;
  cursor: pointer;
  letter-spacing: 0.1rem;
  transition: color .2s;
}

.link:hover {
  color: #008C8E;
}

* ul:not(.list-none) li:before{
  content: none;
}

.contact_us {
  background-image: url('./assets/contact_us.svg');
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-position: left 0px top 0px;
  background-origin: content-box;
  background-size: 180% 120%;
}

.icon_main{
  transition: top 0.2s;
}

.security_img {
  background-image: url('./assets/security_img.svg');
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-position: left 0px top 0px;
  background-origin: content-box;
  background-size: 180% 120%;
}

.know_more_button{
  display: flex;
  align-items: center;
  color: #008C8E;
  border: 1px solid #008C8E;
}

.know_more_button:hover{
  color: white;
  border: 1px solid #008C8E;
  background-color: #008C8E;
}