#navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: top 0.3s;
    z-index: 100;
}

.navMenu {
    margin: 20px 0px 0px 0px;
}

.navLink {
    color: rgb(73, 73, 73);
    background-color: #ebebeb82;
    border-radius: 5px;
    font-size: large;
    padding: 4px 8px 4px 8px;
    margin: 0px 2px 0px 2px;
    letter-spacing: 1px;
    font-weight: 400;
    cursor: pointer;
}

.navLink:hover {
    color: #008C8E;
    background-color: #ebebeb;
    border-radius: 5px;
    border-bottom: 2px;
    border-color: #008C8E;
    border-style: double;
}

.hoverDummy {
    color: #008C8E;
    background: #ebebeb;
    border-radius: 5px;
    border-bottom: 2px;
    border-color: #008C8E;
    border-style: double;
}

.subLink_highlight {
    color: #008C8E;
}

.subLink:hover { 
    color: #ffff;
    background: #008C8E;
}

.carousel .carousel-status{
    display: none;
}